import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from 'reactstrap';
import { graphql } from "gatsby";

import infinity from '../images/lifetime-new/infinity.svg';
import payu from '../images/lifetime-new/payu.svg';

import diet from '../images/lifetime-new/diet.svg';
import support from '../images/lifetime-new/support.svg';
import like from '../images/lifetime-new/like.svg';
import dietition from '../images/lifetime-new/dietition.svg';
import trainingPlans from '../images/lifetime-new/training-plans.svg';

import jadlospisyOkolicznosciowe from '../images/lifetime-new/jadlospisy-okolicznosciowe.svg';
import kopiowaniePosilku from '../images/lifetime-new/kopiowanie-posilku.svg';
import listaZakupow from '../images/lifetime-new/lista-zakupow.svg';
import nawodnienie from '../images/lifetime-new/nawodnienie.svg';
import noWifi from '../images/lifetime-new/no-wifi.svg';
import pomiary from '../images/lifetime-new/pomiary.svg';
import rady from '../images/lifetime-new/rady.svg';
import treningiZAnia from '../images/lifetime-new/treningi-z-ania.svg';
import treningi from '../images/lifetime-new/treningi.svg';
import changeIngredient from '../images/lifetime-new/change-ingredient.svg';

import arrowLeft from '../images/lifetime-new/arrow-left.svg';
import arrowRight from '../images/lifetime-new/arrow-right.svg';

import dvd2 from '../images/lifetime-new/dvd_2.svg';
import dvd5 from '../images/lifetime-new/dvd_5.svg';
import dvd7 from '../images/lifetime-new/dvd_7.svg';
import pytajnik from '../images/lifetime-new/pytajnik.svg';

import star from '../images/lifetime/star.svg';

import appLogo from '../images/lifetime-new/app-logo.png';

import Slider from 'react-slick';
import {USER_COUNT} from '../constants';

import SaleProductPromoBox2 from "../components/SaleProduct/SaleProductPromoBox2";
import SaleProductProvider from "../SaleProduct/SaleProductContext";
import { addToCartLink } from "../SaleProduct/CreateAddToCartLink";

const Page = ({ data }) => {

    const settings1 = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                },
            }
        ],
    };

    const settings2 = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };

    const slider1 = React.createRef();

    return (
        <Layout page="lifetime-new" showMenu={true}>
            <Seo
                title="Dożywotni dostęp! PROMOCJA"
                description="Wymarzona sylwetka, dobre samopoczucie i energia. Dzięki dożywotniemu dostępowi do diety i treningów już zawsze będą one Twoje! Zagwarantuj sobie nieograniczony czasowo dostęp do wszystkich funkcji Diet & Training by Ann."
            />
            <section className="lifetime1">
                <Container>
                    <GatsbyImage
                        image={data.hero.childImageSharp.gatsbyImageData}
                        className="d-none d-md-block" />
                    <span className="tag">Tylko dla Ciebie</span>
                    <h1>Dożywotni dostęp!</h1>
                    <p>Diet &amp; Training by Ann</p>
                </Container>
                <GatsbyImage image={data.hero2.childImageSharp.gatsbyImageData} className="d-md-none" />
            </section>
    
            <section className="lifetime2">
                <Container>
                    <Row>
                        <Col md={{size: 10, offset: 1}}>
                            <div className="gold-box">
                                <p>Zaczęłaś dzięki postanowieniu? <br/>
                                Pokochałaś pyszne dania i satysfakcjonujące treningi? <br/>
                                Poznałaś sposób na szczęśliwe, zdrowe życie? <strong>Ciesz się tym nieprzerwanie!</strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="lifetime3">
                <Container>
                    <img src={infinity} className="mb-4" alt="" />
                    <h2>Decyzja o przyszłości należy do Ciebie!</h2>
                    <p>Wymarzona sylwetka, dobre samopoczucie i energia. Dzięki dożywotniemu dostępowi do diety i treningów już zawsze będą one Twoje! Wybierz dożywotni plan diety i treningów, by zagwarantować sobie nieograniczony czasowo dostęp do wszystkich funkcji Diet &amp; Training by Ann.</p>
                </Container>
            </section>
    
            <section className="lifetime4">
                <Container>
                    <Row>
                        <Col md="6" className="d-none d-md-block">
                            <GatsbyImage
                                image={data.dietAndTraining.childImageSharp.gatsbyImageData}
                                className="mr-4" />
                        </Col>
                        <Col md="6">
                            <h2 className="gradient-text">Dieta + Trening</h2>
                            <p className="mb-5">Dożywotni dostęp do planu diety, a także wszystkich obecnych i nowych zestawów treningowych.</p>
                            
                            <SaleProductProvider tag='landing:lifetime' source='lifetime'>
                                <SaleProductPromoBox2 showOldPrice={true} />
                            </SaleProductProvider>
    
                        </Col>
                    </Row>
    
                    <Row>
                        <Col md={{size: 9, offset: 3}} className="text-right">
                            <p className="border-top d-inline-block pt-5 mb-0 mt-5 mt-md-0">Dożywotni dostęp możesz kupić także na raty, już od 13,67 zł za miesiąc <img src={payu} className="ml-4" alt="" /></p>
                        </Col>
                    </Row>

                    <GatsbyImage
                        image={data.dietAndTraining.childImageSharp.gatsbyImageData}
                        className="mt-4 d-md-none" />
                </Container>
            </section>
    
            <section className="lifetime5">
                <Container>
                    <h2>Gwarancja wszystkich udogodnień!</h2>
                    <Row className="justify-content-center">
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={trainingPlans} alt="" />
                            <p>Nowe<br/>i obecne plany<br/>treningowe</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={diet} alt="" />
                            <p>Następne i&nbsp;dotychczasowe warianty diety</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={dietition} alt="" />
                            <p>Priorytetowa<br/>obsługa<br/>dietetyków</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={support} alt="" />                    
                            <p>Dostęp do&nbsp;najnowszych funkcji</p>
                        </div>
                        <div className="col-6 col-md" >
                            <img src={like} alt="" />
                            <p>Twoja opinia<br/>wpływa na rozwój aplikacji</p>
                        </div>
                    </Row>
                </Container>
            </section>
    
            <section className="lifetime6">
                <Container>
                    <Slider {...settings1} ref={slider1}>   
                        <div>
                            <Row className="align-items-center">
                                <Col xs={{size: 8, offset: 2, order: 2}} md={{size: 3, offset: 1, order: 1}}>
                                    <GatsbyImage
                                        image={data.oddech.childImageSharp.gatsbyImageData}
                                        className="mt-4 mt-md-0" />
                                </Col>
                                <Col xs={{ size: 12, offset: 0, order: 1}} md={{ size: 7, offset: 1, order: 2}}>
                                    <h2 className="gradient-text">Ćwiczenia oddechowe</h2>
                                    <p>Oddech to jedno z najlepszych narzędzi do walki ze stresem: pomaga się uspokoić, skupić na chwili bieżącej lub opanować emocje. Właśnie dlatego oddech warto ćwiczyć i pracować nad tym, żeby był jak najbardziej świadomy.</p>
                                    <p>W aplikacji znajdziesz 3 zestawy ćwiczeń, które Ci w tym pomogą: Skupienie, Spokój i Harmonia.</p>
            
                                    <div className="slider-custom-arrows d-none d-md-block">
                                        <img src={arrowLeft} onClick={()=>{slider1.current.slickPrev()}} alt="" aria-hidden="true" />
                                        <img src={arrowRight} onClick={()=>{slider1.current.slickNext()}} alt="" aria-hidden="true" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row className="align-items-center">
                                <Col xs={{size: 8, offset: 2, order: 2}} md={{size: 3, offset: 1, order: 1}}>
                                    <GatsbyImage image={data.xxx.childImageSharp.gatsbyImageData} className="mt-4 mt-md-0" />
                                </Col>
                                <Col xs={{ size: 12, offset: 0, order: 1}} md={{ size: 7, offset: 1, order: 2}}>
                                    <h2 className="gradient-text">Balans</h2>
                                    <p>Balans to wyjątkowe miejsce w aplikacji Diet & Training by Ann wypełnione nagraniami prosto ze świata przyrody oraz autorską, relaksacyjną muzyką. Dzięki nim w dowolnym momencie możesz poczuć się tak, jakbyś właśnie spacerowała po lesie, siedziała przy ognisku lub była nad brzegiem morza. Przygotowaliśmy dla Ciebie aż 13 wyjątkowych ścieżek!</p>
            
                                    <div className="slider-custom-arrows d-none d-md-block">
                                        <img src={arrowLeft} onClick={()=>{slider1.current.slickPrev()}} alt="" aria-hidden="true" />
                                        <img src={arrowRight} onClick={()=>{slider1.current.slickNext()}} alt="" aria-hidden="true" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row className="align-items-center">
                                <Col xs={{size: 8, offset: 2, order: 2}} md={{size: 3, offset: 1, order: 1}}>
                                    <GatsbyImage
                                        image={data.profil.childImageSharp.gatsbyImageData}
                                        className="mt-4 mt-md-0" />
                                </Col>
                                <Col xs={{ size: 12, offset: 0, order: 1}} md={{ size: 7, offset: 1, order: 2}}>
                                    <h2 className="gradient-text">Mój Profil</h2>
                                    <p>Treningowe osiągnięcia, wykres zmiany masy ciała, treningowy kalendarz, a także propozycje aktywności na dni, kiedy dajesz swojemu organizmowi czas na odpoczynek i regenerację. Wszystko po to, żebyś zaraz po uruchomieniu aplikacji wiedziała, ile drogi już za Tobą… i że cel jest coraz bliżej!</p>
            
                                    <div className="slider-custom-arrows d-none d-md-block">
                                        <img src={arrowLeft} onClick={()=>{slider1.current.slickPrev()}} alt="" aria-hidden="true" />
                                        <img src={arrowRight} onClick={()=>{slider1.current.slickNext()}} alt="" aria-hidden="true" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Slider>
                    <div className="slider-custom-arrows d-md-none">
                        <img src={arrowLeft} onClick={()=>{slider1.current.slickPrev()}} alt="" aria-hidden="true" />
                        <img src={arrowRight} onClick={()=>{slider1.current.slickNext()}} alt="" aria-hidden="true" />
                    </div>
                </Container>
            </section>
    
            <section className="lifetime7">
                <Container>
                    <h2>Tych rzeczy już nigdy Ci nie zabraknie!</h2>
                    <Row className="mb-md-5">
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={changeIngredient} alt="" />
                            <p>Wymiana posiłków i produktów</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={treningi} alt="" />
                            <p>Ponad 4500 dni treningowych</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={pomiary} alt="" />
                            <p>Pomiary i śledzenie postępów</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={listaZakupow} alt="" />                    
                            <p>Interaktywna lista zakupów</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={noWifi} alt="" />
                            <p>Dostęp do treningów także offline</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0 d-md-none" >
                            <img src={kopiowaniePosilku} alt="" />
                            <p>Kopiowanie posiłków na następny dzień</p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md mb-5 mb-md-0 d-none d-md-block" >
                            <img src={kopiowaniePosilku} alt="" />
                            <p>Kopiowanie posiłków na następny dzień</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={rady} alt="" />
                            <p>Rady, wskazówki, motywacja</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={jadlospisyOkolicznosciowe} alt="" />
                            <p>Jadłospisy okolicznościowe</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={nawodnienie} alt="" />                    
                            <p>Monitorowanie nawodnienia</p>
                        </div>
                        <div className="col-6 col-md mb-5 mb-md-0" >
                            <img src={treningiZAnia} alt="" />
                            <p>Treningi z Anią, Mariolą, Eweliną, Kubą i Joanną</p>
                        </div>
                    </Row>
                </Container>
            </section>
    
            <section className="lifetime8">
                <Container>
                    <Row>
                        <Col md={{size: 10, offset: 1}}>
                            <div className="gold-box">
                                <p><strong>CZY WIESZ, ŻE…</strong><br/>
                                Potrzebowałabyś ponad 1450 płyt, żeby nagrać wszystkie treningi z mojej aplikacji! Gdyby każda z nich była w osobnym pudełku, to ułożone w wieżę osiągnęłyby wysokość ponad 8,5 metra!</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="lifetime9">
                <Container>
                    <Row className="align-items-center">
                        <Col md="4" className="text-center">
                            <h2 className="gradient-text">1 aplikacja</h2>
                            <GatsbyImage
                                image={data.iphone.childImageSharp.gatsbyImageData}
                                className="mx-5 mx-md-0" />
                        </Col>
                        <Col md="2"><span className="equal-sign">=</span></Col>
                        <Col md="5">
                            <Row className="align-items-center mb-5">
                                <Col xs="4" className="text-right text-md-left">
                                    <img src={dvd2} alt="" />
                                </Col>
                                <Col xs="8">
                                    <p><strong>MARZEC 2018.<br />
                                    PREMIERA APLIKACJI</strong></p>
                                    <p className="grey">50 płyt DVD</p>
                                </Col>
                            </Row>
    
                            <Row className="align-items-center mb-5">
                                <Col xs="4" className="text-right text-md-left">
                                    <img src={dvd5} alt="" />
                                </Col>
                                <Col xs="8">
                                    <p><strong>MARZEC 2019</strong></p>
                                    <p className="grey">300 płyt DVD</p>
                                </Col>
                            </Row>
    
                            <Row className="align-items-center mb-5">
                                <Col xs="4" className="text-right text-md-left">
                                    <img src={dvd7} alt="" />
                                </Col>
                                <Col xs="8">
                                    <p><strong>GRUDZIEŃ 2020</strong></p>
                                    <p className="grey">1450 płyt DVD</p>
                                </Col>
                            </Row>
    
                            <Row className="align-items-center mb-5">
                                <Col xs="4" className="text-right text-md-left">
                                    <img src={dvd7} alt="" />
                                </Col>
                                <Col xs="8">
                                    <p><strong>2021</strong></p>
                                    <p className="grey">To już PONAD 82 KG PŁYT DVD</p>
                                </Col>
                            </Row>

                            <Row className="align-items-center mb-5">
                                <Col xs="4" className="text-right text-md-left">
                                    <img src={pytajnik} alt="" />
                                </Col>
                                <Col xs="8">
                                    <p><strong>2022</strong></p>
                                    <p className="grey">Już wkrótce kolejne nowości!</p>
                                </Col>
                            </Row>
                            <div className="text-center text-md-left">
                                <a
                                    href={addToCartLink(324, null, 'lifetime')}
                                    className="btn btn-primary btn-auto-width"
                                >
                                    Kup teraz
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="lifetime10">
                <Container>
                    <h2 className="text-center"><span className="violet">{USER_COUNT}+ użytkowników</span><br/>Diet &amp; Training by Ann</h2>
                    <Slider {...settings2}>
                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Ja jestem bardzo zadowolona! Osobiste sympatie
                                lub jej brak do Pani Lewandowskiej nie mają tu
                                nic do rzeczy. Liczy się sport…
                            </p>
                            <span className="sign">- Magdalena</span>
                        </div>
    
                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Aplikacja prosta w użyciu. Bardzo pomocna lista
                                zakupów (można iść do sklepu z telefonem i
                                odhaczać po kolei co już mamy w koszyku)…
                            </p>
                            <span className="sign">- Szopeczka21</span>
                        </div>
    
                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Rewelacja! Proste przepyszne przepisy, zrzucanie
                                zbędnych kilogramów to sama przyjemność
                            </p>
                            <span className="sign">- Barbara</span>
                        </div>
    
                        <div className="panel">
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <img src={star} className="star" alt="" />
                            <p>
                                Proste i smaczne przepisy. Zdrowe i nie
                                pracochłonne. Jestem bardzo zadowolona. Dziękuję
                            </p>
                            <span className="sign">- Ewelina</span>
                        </div>
                    </Slider>
                </Container>
            </section>

            <section className="lifetime11">
                <Container>
                    <h2 className="gradient-text text-center">Trenerzy</h2>
                    <Row>
                        <Col md={{size: 5, offset: 1}} className="mb-5">
                            <p>
                                <GatsbyImage image={data.ania.childImageSharp.gatsbyImageData} className="trainer" />
                                <strong>Anna Lewandowska</strong>
                            </p>
                        </Col>
                        <Col md={{size: 5, offset: 1}} className="mb-5">
                            <p className="text-right text-md-left">
                                <GatsbyImage
                                    image={data.joanna.childImageSharp.gatsbyImageData}
                                    className="trainer d-none d-md-inline-block" />
                                <strong>Joanna Jędrzejczyk</strong>
                                <GatsbyImage
                                    image={data.joanna.childImageSharp.gatsbyImageData}
                                    className="trainer mr-0 ml-3 d-md-none" />
                            </p>
                        </Col>
                        <Col md={{size: 5, offset: 1}} className="mb-5">
                            <p>
                                <GatsbyImage image={data.ewelina.childImageSharp.gatsbyImageData} className="trainer" />
                                <strong>Ewelina Godlewska</strong>
                            </p>
                        </Col>
                        <Col md={{size: 5, offset: 1}} className="mb-5">
                            <p className="text-right text-md-left">
                                <GatsbyImage
                                    image={data.kuba.childImageSharp.gatsbyImageData}
                                    className="trainer d-none d-md-inline-block" />
                                <strong>Jakub Głąb</strong>
                                <GatsbyImage
                                    image={data.kuba.childImageSharp.gatsbyImageData}
                                    className="trainer mr-0 ml-3 d-md-none" />
                            </p>
                        </Col>
                        <Col md={{size: 6, offset: 1}}>
                            <p>
                                <GatsbyImage image={data.mariola.childImageSharp.gatsbyImageData} className="trainer" />
                                <strong>Mariola Bojarska - Ferenc</strong>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="lifetime12">
                <Container>
                    <Row className="align-items-center">
                        <Col md={{size: 2, offset: 1}}>
                            <img src={appLogo} className="mb-5 mb-md-0" alt="" />
                        </Col>
                        <Col md={{size: 8}}>
                            <h2>Ilość abonamentów ograniczona!</h2>
                            <p>Nie zwlekaj z decyzją! Już dziś zaplanuj swoje szczęście. Dieta, treningi i motywacja to niezbędne elementy tej ścieżki.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="lifetime13">
                <Container>
                    <Row className="align-items-center">
                        <Col md="8">
                            <h2 className="gradient-text mb-5"><span className="white">Przygotowuję już</span> nowe funkcje dla&nbsp;Ciebie!</h2>
                            <p>Pracuję nad nowymi treningami i funkcjami w Diet & Training by Ann. Dzięki dożywotniemu dostępowi będziesz mieć gwarancję dostępu do nowych funkcji, planów diety i treningów!</p>

                            <p className="violet mt-5">Nowe plany treningowe<br/>już wkrótce!</p>
                        </Col>
                        <Col md="4">
                            <GatsbyImage image={data.ania2.childImageSharp.gatsbyImageData} />
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="lifetime14" id="buy">
                <Container>
                    <Row>
                        <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                            <GatsbyImage
                                image={data.dietAndTraining.childImageSharp.gatsbyImageData}
                                className="mt-4 mt-md-0 mr-md-4" />
                        </Col>
                        <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                            <h2>Dieta + Trening</h2>
                            <p className="mb-5">Dożywotni dostęp do planu diety, a także wszystkich obecnych i nowych zestawów treningowych.</p>
                            
                            <SaleProductProvider tag='landing:lifetime' source='lifetime'>
                                <SaleProductPromoBox2 showOldPrice={true} />
                            </SaleProductProvider>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="lifetime15">
                <Container>
                    <h2 className="mb-5">Jak kupić dożywotni dostęp<br/>na raty? To bardzo proste!</h2>
                    <Row>
                        <Col xs={{size: 8, offset: 2}} md={{size: 3, offset: 0}}>
                            <div className="gold-border-box">
                                <h2 className="gradient-text">01</h2>
                                <p>Kliknij przycisk “Kup&nbsp;teraz”</p>
                                <a
                                    href={addToCartLink(324, null, 'lifetime')}
                                    className="btn btn-primary btn-auto-width mt-4"
                                >
                                    Kup teraz
                                </a>
                            </div>
                        </Col>
                        <Col xs={{size: 8, offset: 2}} md={{size: 3, offset: 0}}>
                            <div className="gold-border-box">
                                <h2 className="gradient-text">02</h2>
                                <p>Jako formę płatności wybierz PayU / płatność online</p>
                            </div>
                        </Col>
                        <Col xs={{size: 8, offset: 2}} md={{size: 3, offset: 0}}>
                            <div className="gold-border-box">
                                <h2 className="gradient-text">03</h2>
                                <p>Na stronie PayU wybierz płatność “Raty”</p>
                            </div>
                        </Col>
                        <Col xs={{size: 8, offset: 2}} md={{size: 3, offset: 0}}>
                            <div className="gold-border-box">
                                <h2 className="gradient-text">04</h2>
                                <p>Kliknij przycisk "Przejdź do formularza ratalnego" i&nbsp;uzupełnij dane, o&nbsp;które prosi PayU.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    
        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "lifetime-new/trenerzy.png" }) {...fluidImage1920}
        hero2: file(relativePath: { eq: "lifetime-new/trenerzy-mobile.png" }) {...fluidImage1920}
        dietAndTraining: file(relativePath: { eq: "lifetime-new/diet-and-training.png" }) {...fluidImage960}
        oddech: file(relativePath: { eq: "lifetime-new/oddech.png" }) {...fluidImage960}
        profil: file(relativePath: { eq: "lifetime-new/profil.png" }) {...fluidImage960}
        xxx: file(relativePath: { eq: "lifetime-new/xxx.png" }) {...fluidImage960}
        iphone: file(relativePath: { eq: "lifetime-new/iphone.png" }) {...fluidImage960}

        ania: file(relativePath: { eq: "lifetime-new/ania.png" }) {...fluidImage960}
        ewelina: file(relativePath: { eq: "lifetime-new/ewelina.png" }) {...fluidImage960}
        joanna: file(relativePath: { eq: "lifetime-new/joanna.png" }) {...fluidImage960}
        kuba: file(relativePath: { eq: "lifetime-new/kuba.png" }) {...fluidImage960}
        mariola: file(relativePath: { eq: "lifetime-new/mariola.png" }) {...fluidImage960}

        ania2: file(relativePath: { eq: "lifetime-new/ania2.png" }) {...fluidImage960}
    }
`;
