import React, { Component } from "react";
import { SaleProductConsumer } from "../../SaleProduct/SaleProductContext";
import SaleProductPlaceholderSection from "./SaleProductsPlaceholder";
import { addToCartLink } from "../../SaleProduct/CreateAddToCartLink";

export default class SaleProductPromoBox extends Component {
    render() {
        return (
            <SaleProductConsumer>
                {
                    ({ saleProducts, isLoading, promoCode, source }) =>
                    isLoading ? (
                        <>
                            <SaleProductPlaceholderSection />
                        </>
                    ) : (
                        saleProducts.map(saleProduct => (
                            <div className="promo-box" key={saleProduct.id}>
                                <h3>{saleProduct.productCardTitle}</h3>
                                <p>
                                    {
                                        saleProduct.hasActivePromo && this.props.showOldPrice ? (
                                            <React.Fragment>
                                                <span
                                                    className="old-price"
                                                >
                                                    {`${saleProduct.regularPrice.toString()}`}
                                                </span>
                                                <br/>
                                                <span
                                                    data-test="product-price"
                                                    className="product-price"
                                                >
                                                    {`${saleProduct.price.toString()}`}
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            <span
                                                data-test="product-price"
                                                className="product-price"
                                            >
                                                {`${saleProduct.price.toString()}`}
                                            </span>
                                        )
                                    }
                                    <br />
                                </p>
                                    
                                <h3>Oferta dla Ciebie</h3>
                                <a
                                    data-testid="add-to-cart"
                                    href={addToCartLink(saleProduct.id, promoCode, source)}
                                    className="btn btn-primary btn-auto-width"
                                >
                                    Kup teraz
                                </a>
                            </div>
                        ))               
                    )
                }
            </SaleProductConsumer>
        );
    }
}
